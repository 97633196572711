@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-alpine.css";
@import "~/styles/tables.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

html{
    scroll-behavior: smooth;
}

h1, .h1 {
    @apply text-4xl font-bold;
}
h2, .h2 {
    @apply text-3xl font-medium;
}
h3, .h3 {
    @apply text-2xl font-medium;
}

.table {
    outline-offset: -1px;
    outline: 1px solid;
    border-collapse: collapse;
    @apply rounded-md text-sm overflow-hidden;
}
.table td, .table th {
    @apply text-center px-2 py-0 border border-brand-deepaqua-light;
}
.table th {
    @apply font-bold py-1;
}

/* Style the little cagr table next to charts */
.cagr-table td {
    @apply px-2 py-0 text-left;
}
.cagr-table strong {
    @apply whitespace-nowrap;
}

/* Style the financial strength table */
.f-strengths-table td {
    @apply py-1;
}
.f-strengths-table td:first-child {
    @apply font-bold text-left min-w-[480px];
}
.f-strengths-table td:not(:first-child) {
    @apply text-right;
}

.fast-track-competitive-adv-table td {
    @apply py-1;
}
.fast-track-competitive-adv-table td:first-child {
    @apply min-w-[280px] text-left;
}
.fast-track-competitive-adv-table td:not(:first-child) {
    @apply w-[100px];
}
.fast-track-competitive-adv-table td:not(:first-child) > div {
    @apply justify-center;
}

.emotion-Modal-title {
    @apply !text-xl !leading-normal;
}


/* Chrome, Safari, Edge, Opera */
.pre-seed-num-input::-webkit-outer-spin-button,
.pre-seed-num-input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
.pre-seed-num-input {
    -moz-appearance: textfield;
}
