.ag-root-wrapper {
    flex-grow: 1;
}

.ag-theme-alpine {
    --ag-cell-horizontal-border: solid rgba(0, 0, 0, 0.25);
    --ag-row-hover-color: #DBE5DD;
    --ag-odd-row-background-color: #ffffff;
    --ag-font-size: 14px;
    --ag-grid-size: 4px;
    --ag-cell-horizontal-padding: 3px;
}

.ag-theme-alpine .ag-layout-auto-height .ag-center-cols-clipper {
    min-height: initial;
}

.ag-cell-value {
    word-break: break-word;
}
